<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-select class="option-item" v-model="searchKeyValue" placeholder="请选择">
					<el-option v-for="item in searchKeyOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<el-input class="input-item" style="margin-left:5px;width:250px" v-model="searchKey" @keyup.enter.native="handleFilter" :placeholder="`请输入${searchKeyPlaceholder[searchKeyValue]}`"></el-input>
			</div>
			<!-- 时间筛选 -->
			<div class="filter-item">
				<label class="label">时间筛选: </label>
				<el-select v-model="TimeType" style="width: 140px;">
					<el-option v-for="item in timeTypeList" :key="item.id" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
				<el-date-picker style="margin-left:5px;" v-model="startTime" :picker-options='maxOptions' format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
				 type="datetime" placeholder="开始时间">
				</el-date-picker>
				<span style="margin:0 5px;">~</span>
				<el-date-picker v-model="endTime"  format="yyyy-MM-dd HH:mm:ss"  :picker-options='minOptions'  default-time="23:59:59" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
				 placeholder="结束时间">
				</el-date-picker>
			</div>
			<!-- 退款类型 -->
			<div class="filter-item">
				<label class="label">退款类型: </label>
				<el-select class="option-item" v-model="typeValue" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- 退款方式 -->
			<div class="filter-item">
				<label class="label">退款方式: </label>
				<el-select class="option-item" v-model="wayValue" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in wayOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- 发货状态 -->
			<div class="filter-item">
				<label class="label">发货状态: </label>
				<el-select class="option-item" v-model="shipValue" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in shipOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- 退款状态 -->
			<div class="filter-item">
				<label class="label">退款状态: </label>
				<el-select class="" v-model="refundValue" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in refundOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'downloadRefund'">
					<el-button style="margin-left:30px;width:100px;" @click="handleExport">导出</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading='loading'>
				<el-table-column prop="SupportNo" label="退款单">
					<template slot-scope="scope">
						<div style="color:red" v-if="scope.row.SendTypeDisplay">[{{scope.row.SendTypeDisplay}}]</div>
						<div>{{scope.row.SupportNo}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="SupportWayDisplay" label="退款方式"></el-table-column>
				<el-table-column prop="SupportTypeDisplay" label="退款类型"></el-table-column>
				<el-table-column prop="ProductCount" label="退款数量"></el-table-column>
				<el-table-column prop="RefundMoney" label="退款金额"></el-table-column>
				<el-table-column label="下单人">
					<template slot-scope="scope">
						<div>{{scope.row.OrderReceiveName}}</div>
						<div>{{scope.row.OrderReceivePhone}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="SendStateDisplay" label="发货状态"></el-table-column>
				<el-table-column prop="AddTime" label="申请时间"></el-table-column>
				<el-table-column prop="SupportStateDisplay" label="退款状态"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div class="table-button" @click="handleDetailShow(scope.row.SupportNo)">详情</div>

						<button-permissions :datas="'passRefund'" v-if="(scope.row.SupportState == 1 && scope.row.SupportWay  == 1)||scope.row.SupportState == 4">
							<div class="table-button" @click="handleAgree(scope.row)">同意退款</div>
						</button-permissions>

						<button-permissions :datas="'failRefund'"  v-if="(scope.row.SupportState == 1 && scope.row.SupportWay  == 1)||scope.row.SupportState == 4">
							<div class="table-button" style="color:#F56C6C" @click="handleDisagree(scope.row,1)">不同意退款</div>
						</button-permissions>
						
						<button-permissions :datas="'passReturns'" v-if="(scope.row.SupportState == 1 && scope.row.SupportWay  == 2)">
							<div class="table-button" @click="handleApply(scope.row)">同意申请</div>
						</button-permissions>

						<button-permissions :datas="'failReturns'" v-if="(scope.row.SupportState == 1 && scope.row.SupportWay  == 2)">
							<div class="table-button" style="color:#F56C6C" @click="handleDisagree(scope.row,0)">不同意申请</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="20"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>

		<!-- 分销礼包退款 -->
		<el-dialog :visible.sync="distributionShow" width="500px" class="data-dialog">
			<div cls>
				<div>该订单为分销礼包订单，退款成功后，请确认是否需要同时取消推手授权？</div>
				<el-form style="margin-top:20px;margin:20px auto 0 auto ">
					<el-form-item required>
						<el-radio v-model="divorceGiftValue" :label="true">取消授权</el-radio>
						<el-radio v-model="divorceGiftValue" :label="false">不取消授权</el-radio>
					</el-form-item>
				</el-form>
				<div class="footer">
					<el-button style="width:150px;" @click="distributionShow=false">关闭</el-button>
					<el-button style="width:150px;" type="primary" @click="saveDistributionRefund" v-loading='loading'>确认</el-button>
				</div>
			</div>
		</el-dialog>

		<!-- 不同意退款/申请 -->
		<el-dialog :title='`不同意${showDisagreeTitle}`' :visible.sync="showDisagree" width="600px" class="dialog data-dialog">
			<div class="disagree-dialog">
				<el-form>
					<el-form-item label="拒绝理由：" required>
						<el-input type="textarea" :rows="5" maxlength="300" style="width:450px;" placeholder="请输入拒绝理由" v-model="disagreeReason"></el-input>
					</el-form-item>
				</el-form>
				<div class="footer">
					<el-button style="width:150px;" @click="cancleDisagreeRefund">关闭</el-button>
					<el-button v-if='showDisagreeTitle=="退款"' style="width:150px;" type="primary" v-loading='loading' @click="saveDisagreeRefund">确认</el-button>
					<el-button v-if='showDisagreeTitle=="申请"' style="width:150px;" type="primary" v-loading='loading' @click="saveDisagreeApply">确认</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import apiList from '@/api/other'
	import config from '@/config/index'
	import {
		parseTime
	} from '@/utils/index'
	import { async } from 'q';import buttonPermissions from '@/components/buttonPermissions';

	export default{
		components: {
			buttonPermissions
		},
		data() {
			return {
				loading: false,
				baseAPI: config.BASE_URL,
				exportUrl:config.EXPORT_URL,
				goUrls:config.GO_URL,
				searchKey: '',
				searchKeyValue: 1,
				searchKeyPlaceholder: ['订单号', '退款单号', '物流单号', '商品名称或编码'],
				searchKeyOptions: [{
					value: 0,
					label: '订单号'
				}, {
					value: 1,
					label: '退款单号'
				}, {
					value: 2,
					label: '物流单号'
				}, {
					value: 3,
					label: '商品名称或编码'
				}],
				TimeType:0,
				timeTypeList: [{
						id: 0,
						value: '退款申请时间'
					},
					{
						id: 1,
						value: '退款结束时间'
					},
					{
						id: 2,
						value: '订单支付时间 '
					}
				],
				startTime: '',
				endTime: '',
				typeValue: null,
				typeOptions: [{
					value: 0,
					label: '售中退款'
				}, {
					value: 1,
					label: '售后退款'
				}],
				wayValue: null,
				wayOptions: [{
					value: 1,
					label: '仅退款 '
				}, {
					value: 2,
					label: '退货退款'
				}, {
					value: 3,
					label: '主动退款'
				}],
				shipValue: null,
				shipOptions: [{
					value: 0,
					label: '未发货'
				}, {
					value: 1,
					label: '已发货'
				}],
				refundValue: null,
				refundOptions: [{
					value: 1,
					label: '退款申请待商家处理'
				}, {
					value: 2,
					label: '商家不同意退款申请，待买家处理'
				}, {
					value: 3,
					label: '商家已同意退货申请，待买家寄回商品'
				}, {
					value: 4,
					label: '买家已寄回商品，待商家确认收货'
				}, {
					value: 5,
					label: '商家拒绝收货，待买家处理'
				}, {
					value: 6,
					label: '退款成功'
				}, {
					value: 7,
					label: '退款关闭'
				}],
				receiveMessage: {},
				tableData: [],
				page: {
					total: 0,
					current: 1,
					size: 20
				},
				distributionShow: false,
				currentOrder: {},
				showDisagree: false,
				showDisagreeTitle: '',
				divorceGiftValue: true,
				disagreeReason: ''
			}
		},
		created() {
			this.searchKey = this.$route.query.OrderNo ? this.$route.query.OrderNo : '';
			if (this.$route.query.OrderNo) {
				this.searchKeyValue = 0
			}
			this.initTime()
			this.initReceive()
			this.getList()
		},
		computed: {
			minOptions: function() {
				let limitTime = this.startTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) 
							)
						}
					}
				}
			}
		},
		methods: {
			jumpToDetail(Id) {
				this.$router.push({
					path: '/Customer/CustomerDetail',
					query: {
						Id: Id
					}
				})
			},
			initTime() {
				let date = new Date()
				let year = date.getFullYear()
				let month = date.getMonth()
				let currentMonth = (month + 1) > 9 ? (month + 1) : ('0' + (month + 1))
				let time = year + '-' + currentMonth + '-' + '01' + ' ' + '00:00:00'
				// this.startTime = time
				// let end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
				// this.endTime = parseTime(end)
			},
			async initReceive() {
				let result = await apiList.supplierOrderInit()
				this.receiveMessage = result.Result
			},
			// 获取列表数据
			async getList() {
				this.loading = true
				try {
					let data = {
						Keywords: this.searchKey,
						KeywordsType: this.searchKeyValue,
						TimeType:this.TimeType,
						SupportWay: this.wayValue,
						SupportState: this.refundValue,
						SupportType: this.typeValue,
						SendState: this.shipValue,
						StartTime: this.startTime,
						EndTime: this.endTime,
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
					}
					let result = await apiList.supplierOrderIndex(data);
					this.tableData = result.Result.Results
					this.page.total = result.Result.Total

				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			handleFilter() {
				this.page.current=1
				if(!this.page.total){
					this.page.size=20
				}				
				this.getList()
			},
			//导出
			handleExport() {
				this.loading = true;
				try {
					let url = this.exportUrl+'/pc/supplier-order-support/index-export?' +
						'Keywords=' + this.searchKey +
						'&KeywordsType=' + this.searchKeyValue +
						'&SupportWay=' + (this.wayValue ? this.wayValue : '') +
						'&SupportState=' + (this.refundValue ? this.refundValue : '') +
						'&SupportType=' + (this.typeValue ? this.typeValue : '') +
						'&StartTime=' + (this.startTime == null ? '' : this.startTime) +
						'&EndTime=' + (this.endTime == null ? '' : this.endTime) +
						'&TimeType=' + this.TimeType +
						'&SendState=' + (this.shipValue ? this.shipValue : '')
					window.open(url);
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			handleDetailShow(num) {
				let headsUrls=process.env.NODE_ENV == 'production'?'index.html#':''
				let url=this.goUrls+headsUrls+'/order/supplyRefund/supplyRefundDetails?id='+num				
				window.open(url)	
				
				// this.$router.push({
				// 	path: '/order/refundAfterSale/refundDetails',
				// 	query: {
				// 		id: num
				// 	}
				// })
			},
			//同意退款
			handleAgree(val) {
				this.currentOrder = val
				this.$confirm(`是否确认同意此次仅退款申请？审核通过后，将通过微信账户给买家退款。退款金额：${val.RefundMoney}元（含运费：${val.RefundFreightMoney}元)`, '提示', {
					confirmButtonText: '确认退款',
					cancelButtonText: '关闭',
				}).then(async () => {
					try {
						if (val.OrderType == 2 && (val.OrderState == 4 || val.OrderState == 5)) {
							this.divorceGiftValue = true
							this.distributionShow = true
						} else {
							let result = await apiList.supplierOrderAgreeRefund({
								IsCancelAuth: false,
								SupportNo: val.SupportNo,
								PayNo:val.PayNo
							})
							this.$message({showClose: true,
								type: 'success',
								message: '退款成功!'
							});
							this.getList()
						}
					} catch (e) {
						// this.$message({showClose: true,
						// 	type: 'error',
						// 	message: '退款失败!'
						// });
					}
				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '取消同意退款'
					});
				})
			},
			//保存同意退款
			async saveDistributionRefund() {
				try {
					let data = {
						IsCancelAuth: this.divorceGiftValue,
						SupportNo: this.currentOrder.SupportNo
					}
					let result = await apiList.supplierOrderAgreeRefund(data)
					this.$message({showClose: true,
						type: 'success',
						message: '退款成功!'
					});
					this.getList()
				} catch (e) {
					this.$message({showClose: true,
						type: 'error',
						message: '退款失败!'
					});
					this.distributionShow = false
				} finally {
					this.distributionShow = false
				}
			},
			//同意申请
			handleApply(val) {
				this.currentOrder = val
				if (this.receiveMessage.ReceiveProvince == '') {
					this.$confirm(`请先设置退货寄回地址`, '提示', {
						confirmButtonText: '前往设置',
						showCancelButton: false,
					}).then(() => {
						this.$router.push({
							path: '/setting/index'
						})
					})
				} else {
					this.$alert(
						`<div>此次退款方式为退货退款，同意申请后，需要买家将商品寄回，寄回地址：</div>
					<div style='color: #999;'>${this.receiveMessage.ReceiveProvince}${this.receiveMessage.ReceiveCity}${this.receiveMessage.ReceiveArea}·${this.receiveMessage.ReceiveAddress}</div>
					<div style='color: #999;'>${this.receiveMessage.ReceiveName}（${this.receiveMessage.ReceivePhone}）</div>
					<div>是否确认同意此次退货退款申请？</div>`,
						'提示', {
							dangerouslyUseHTMLString: true,
							showCancelButton: true,
							confirmButtonText: '同意申请',
							cancelButtonText: '关闭',
						}).then(async () => {
						try {
							let result = await apiList.supplierOrderAgreeApply({
								SupportNo: val.SupportNo
							})
							this.$message({showClose: true,
								type: 'success',
								message: '申请成功!'
							});
							this.getList()
						} catch (e) {

						}
					}).catch(() => {
						this.$message({showClose: true,
							type: 'info',
							message: '取消同意退款'
						});
					})
				}
			},
			//不同意退款/申请
			handleDisagree(val, index) {
				this.currentOrder = val
				this.disagreeReason = ''
				this.showDisagree = true
				this.showDisagreeTitle = index ? '退款' : '申请'
			},
			//保存不同意退款
			async saveDisagreeRefund() {
				if (this.disagreeReason == '') {
					this.$message({showClose: true,
						type: 'error',
						message: '请输入拒绝理由!'
					});
				} else {
					this.loading = true
					try {
						let result = await apiList.supplierOrderRefuseRefund({
							SupportNo: this.currentOrder.SupportNo,
							FailedPassReason: this.disagreeReason
						})
						if (this.currentOrder.SupportWay == 1) {
							this.$message({showClose: true,
								type: 'success',
								message: '退款关闭'
							});
						} else {
							this.$message({showClose: true,
								type: 'success',
								message: '商家拒绝收货，待买家处理!'
							});
						}
						this.getList()
						this.showDisagree = false
						this.loading = false
					} catch (e) {
						this.loading = false
					} finally {
						this.loading = false
					}
				}
			},
			//保存不同意申请
			async saveDisagreeApply() {
				if (this.disagreeReason == '') {
					this.$message({showClose: true,
						type: 'error',
						message: '请输入拒绝理由!'
					});
				} else {
					this.loading = true
					try {
						let result = await apiList.supplierOrderRefuseApply({
							SupportNo: this.currentOrder.SupportNo,
							FailedPassReason: this.disagreeReason
						})
						this.showDisagree = false
						this.getList()
						this.$message({showClose: true,
							type: 'success',
							message: '商家不同意退款申请，待买家处理'
						});
						this.loading = false
					} catch (e) {
						this.loading = false
					}
					this.loading = false
				}
			},
			//关闭不同意申请/退款
			cancleDisagreeRefund() {
				this.showDisagree = false
				this.$message({showClose: true,
					type: 'info',
					message: '已取消操作'
				});
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;
	}

	.input-item {
		margin-left: 5px;
	}

	.option-item {
		/deep/.el-input {
			width: 150px !important;
		}
	}

	.table-button {
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	.dialog {
		width: 100%;

		/deep/.el-dialog__header {
			border-bottom: 1px solid #ddd;
		}

		.content {
			padding: 0 30px;
			display: flex;
			flex-direction: row;

			.right {
				margin-left: 20px;
			}

			.text {
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}

		.button {
			margin: 0 auto;
			width: 200px;
		}
	}

	.data-dialog {
		.value {
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}

		.item {
			color: #666;
			margin-top: 10px;
		}
	}

	.divorce-gift {
		/deep/.el-dialog__body {
			padding-top: 0px;
		}

		.footer {
			margin-top: 20px;
			text-align: center
		}
	}

	.footer {
		margin-top: 20px;
		text-align: center
	}
</style>
